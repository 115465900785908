import { render, staticRenderFns } from "./loginPassword.vue?vue&type=template&id=6310797a"
import script from "./loginPassword.vue?vue&type=script&lang=js"
export * from "./loginPassword.vue?vue&type=script&lang=js"
import style0 from "./loginPassword.vue?vue&type=style&index=0&id=6310797a&prod&lang=scss"
import style1 from "./loginPassword.vue?vue&type=style&index=1&id=6310797a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports