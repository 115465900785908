<template>
    <div class="loginPassword scrollbar" :class="$mq">
        <!-- <div class="title-pin">{{ $t('login.access_pin') }}</div> -->

        <!-- <template v-if="['landscape', 'desktop', 'portrait'].includes($mq)">
            <div class="error-pin" v-if="errorPass">
                {{ $t('login.error_pin') }}
            </div>
        </template> -->
        <div class="numpad-box-container">
            <numpad @sendValue="setProcessValue($event)" @autoEnter="save(employeeSelected.id, pin)" :error="errorPass" :pinType="'password'" :givenValue="pin" class="numpad-content"></numpad>
        </div>
        <div class="btons-container">
            <button @click="goBack()" class="btn cancel">{{ $t('login.cancel') }}</button>
            <button class="btn action" :class="{ disabled: pin.length < 4, loading: loading }" @click="save(employeeSelected.id, pin)">{{ $t('login.access') }}</button>
        </div>
    </div>
</template>

<script>
import numpad from '@/components/inputs/Numpad'
import employee from '@/components/domain/login/employee'
export default {
    components: {
        numpad,
        employee
    },
    name: 'loginPassword',
    props: {
        employeeSelected: { type: undefined }
    },
    data() {
        return {
            pin: '',
            errorPass: false,
            loading: false,
            doingLogin: false
        }
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        getRol(rolID) {
            if (rolID) {
                return this.$store.getters['employee/getRol'](rolID)
            } else {
                return false
            }
        },
        goBack() {
            this.$emit('back')
        },
        setProcessValue(result) {
            this.errorPass = false
            if (Object.keys(this.employeeSelected).length > 0) {
                this.pin = result
            }
        },
        save(userID, pass) {
            var self = this
            self.errorPass = false
            self.loading = true

            var validation = []

            if (pass == '' || pass.length <= 0) validation.push(false)

            if (validation.indexOf(false) == -1) {
                const licenseEndDate = this.$store.getters['getLicenseEndDate']
                this.$store.commit('setShowExpiredLicense', licenseEndDate)

                self.$overlay.loading()
                self.doingLogin = true
                self.$store
                    .dispatch('loginUser/doLogin', {
                        employee: userID,
                        password: sha256(pass),
                        mq: this.$mq
                    })
                    .then(function (response) {
                        self.$store.commit('loginUser/setVisibleConnect', false)
                        if (!response) {
                            self.errorPass = true
                            self.pin = ''
                        }
                        self.loading = false
                        self.$overlay.hide()
                        // self.doingLogin = false
                    })
            } else {
                self.loading = false
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.loginPassword {
    @include display-flex();
    @include flex-direction(column);
    @include align-items();
    width: 100%;
    height: 100%;
    //overflow: hidden;

    &.disabled {
        display: none;
        cursor: not-allowed;

        .numpad-content {
            .num-pad {
                .key {
                    cursor: not-allowed;
                }
            }
        }
    }

    .title-pin {
        @include font-size(l);
        color: $main;
        font-family: $text-bold;
        text-align: center;
        height: 50px;
        line-height: 50px;
        margin-bottom: 15px;
    }

    .numpad-content {
        .pin {
            background-color: $main-t80;
        }
    }

    .send {
        @include font-size(m);
        font-family: $text-sbold;
        width: 150px;
        margin-top: 15px;
    }
    .btons-container {
        @include display-flex();
        @include justify-content(center);
        @include flex-wrap(wrap);
        width: 100%;
        margin-top: 10px;

        .btn {
            @include font-size(m);
            font-family: $text-bold;
            height: 40px;
            min-width: 100px;
            width: auto;
            line-height: 40px;
            text-align: center;
            padding: 0px 12px;
            transition: background-image 1s 2s;

            &.cancel {
                background-color: $neutro;
                color: $main;
                margin-right: 30px;
            }
        }
    }
    .error-pin {
        @include font-size(s);
        font-family: $text-bold;
        color: $error-s50;
    }
}

@media (orientation: portrait) {
    .loginPassword {
        // @include justify-content(center);
        &.disabled {
            display: none;
        }
        .btons-container {
            height: 60px;
            .btn {
                @include font-size(l);
                height: 60px;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.loginPassword.portrait {
    height: 100%;

    overflow: hidden;

    .numpad-box-container {
        @include display-flex();
        @include justify-content(center);
        height: calc(100% - 80px);
        width: 100%;
        overflow: hidden;

        @media (max-height: 640px) {
            overflow: scroll;
        }

        .numpad-container.portrait {
            // min-height: 460px;
            min-height: 380px;

            .num-padtop {
                margin: 0px;
                padding-top: 4px;
                padding-bottom: 4px;
                padding-bottom: 8px;
                display: flex;
                // max-height: calc(100% - 364px);

                .password-pin .number-key {
                    min-width: 43px;
                }
            }
            .num-pad {
                overflow: hidden;
                .key {
                    width: calc((100% / 3) - 19px);
                    max-height: 65px;
                }
            }
        }
        &.no_click {
            pointer-events: none;
        }
    }
    .btons-container {
        @include display-flex();
        @include justify-content(center);
        @include flex-wrap(wrap);
        width: 100%;
        margin-top: 10px;
        height: 50px;
        max-height: 80px;
        overflow: hidden;

        .btn {
            @include font-size(sm);
            font-family: $text-bold;
            height: 50px;
            min-width: 100px;
            width: calc(50% - 10px);
            line-height: 50px;
            text-align: center;
            padding: 0px 12px;
            transition: background-image 1s 2s;

            &.cancel {
                background-color: $neutro;
                color: $main;
                margin-right: 10px;
            }
        }
    }
}
</style>
